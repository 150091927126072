@font-face {
  font-family: 'Disco Diva';
  src: url('DiscoDiva.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  overflow: hidden
}